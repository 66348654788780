@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
  font-family: "Montserrat", sans-serif;
}

body {
  @apply font-mulish;
}
li {
  @apply cursor-pointer;
}
button {
}
